.backdrop {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background: rgba(128, 128, 128, 0.438);
  z-index: 3;
}
.popContainer {
  width: 95%;
  height: 377px;
  background: #fff;
  border-radius: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 13px;
  position: relative;
}
.popHeader {
  font-family: "Praxis-Heavy" !important;
  font-size: 24px !important;
  color: #232323;
}
.popSubHeader {
  font-size: 11px !important;
  color: #232323;
  font-weight: 600 !important;
  margin-bottom: 10px !important;
}
.popSubHeader span {
  color: #e4026e;
  font-weight: 600 !important;
  text-decoration: underline;
  cursor: pointer;
}
.OtpBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  position: relative;
}
.OtpBox input {
  width: 50px;
  height: 50px;
  border: 1px solid rgba(77, 77, 77, 0.671);
  border-radius: 7px;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}
.OtpBox input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.OtpBox input[type="number"] {
  -moz-appearance: textfield;
}
.OtpBox input:focus {
  outline: none;
}
.nRotpText {
  font-weight: 600 !important;
}
.rsOptText {
  color: #e4026e;
  font-weight: 600 !important;
  cursor: pointer;
}
.otpNM {
  color: red;
  text-align: center;
  position: absolute;
  top: 224px;
  font-size: 14px;
}
.otpInputErr {
  border: 1px solid red !important;
}
.otpInputSu {
  border: 1px solid rgb(65, 212, 163) !important;
}
.otpExpText {
  position: absolute;
  top: -28px;
  font-size: 14px !important;
  color: rgb(65, 212, 163);
  font-weight: 600 !important;
}

/* Tabe view */
@media only screen and (min-width: 768px) {
  .popContainer {
    width: 620px;
    height: 420px;
    gap: 24px;
  }
  .popHeader {
    font-size: 30px !important;
  }
  .popSubHeader {
    font-size: 18px !important;
  }
  .otpNM {
    top: 219px;
  }
}

/* Desktop view */
@media only screen and (min-width: 1000px) {
}
