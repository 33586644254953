.pricingPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}
.pricMainBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6% 0;
  gap: 80px;
}
.pTextBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pImgBox {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pImgBox img {
  width: 100%;
}
.pricMainBox .policyHeader1 {
  margin: 0 !important;
}

/* Tabe view */
@media only screen and (min-width: 768px) {
  .pTextBox {
    width: 80%;
  }
  .pImgBox {
    width: 80%;
  }
}

/* Desktop view */
@media only screen and (min-width: 1000px) {
  .pricMainBox {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0px;
  }
  .pTextBox {
    width: 50%;
  }
  .pImgBox {
    width: 50%;
  }
}
