.HomeContainer {
  width: 100%;
  position: relative;
}
.heroSection {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-top: 38%;
}
.heroSection .homeBourBg {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  z-index: -1;
}
.homeHeader {
  font-family: "Praxis-Heavy" !important;
  font-size: 32px !important;
  color: #fff;
  text-align: center;
}
.homeSubText {
  font-size: 13px !important;
  color: #fff;
}
.homeTikBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin: 20px 0;
  padding: 0 20px;
}
.tikTextBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 15px;
}
.tikTextBox img {
  width: 16px;
}
.tikTextBox p {
  font-size: 10px !important;
  color: #fff;
  font-weight: 600 !important;
}
.homeSub2Text {
  font-size: 16px !important;
  color: #fff;
  margin: 10px 0 !important;
}
.homeContactBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.homeContactBox .NIconBox {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffff;
  border-radius: 7px;
}
.homeContactBox .NIconBox img {
  width: 16px;
}
.homeGrayBg {
  width: 100%;
  position: absolute;
  bottom: 0px;
  z-index: -1;
}

.rLine {
  width: 20px;
  position: absolute;
  right: 0px;
}
.wline {
  width: 20px;
  position: absolute;
  left: 0px;
}
.leftPadding {
  padding-left: 20px;
  display: flex;
  display: flex;
  align-items: start;
}
.rightPadding {
  padding-right: 20px;
  display: flex;
  align-items: end;
}
.graphImg1 {
  width: 80%;
}
.graphImg2 {
  width: 70%;
}
.graphImg3 {
  width: 70%;
}
.hero2ndSection {
  width: 100%;
  background: #1d1e2d;
  position: relative;
}
.h2Header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.h2Header img {
  width: 200px;
}
.h2Header p {
  color: #fff;
  font-family: "Praxis-Heavy" !important;
  font-size: 30px !important;
}
.hero2SubHeader {
  width: 100%;
  text-align: center;
  font-size: 14px !important;
  color: #fff;
  margin-top: 15px !important;
}
.BookingCardBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 150px;
  margin-top: 179px;
}
.bookingCard {
  width: 350px;
  height: 223px;
  border-radius: 12px;
  background: #ffffff21;
  padding: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  position: relative;
}
.bookingCard img {
  position: absolute;
}
.bookingCardI1mg {
  top: -102px;
}
.bookingCardI2mg {
  top: -102px;
}
.bookingCardI3mg {
  top: -102px;
}
.bookingCard p {
  font-size: 24px !important;
  font-family: "Praxis-Heavy" !important;
  color: #fff;
  text-align: center;
}
.bookingCard span {
  color: #ffffffd5;
  font-size: 16px !important;
  text-align: center;
}
.graphSection {
  width: 100%;
  margin-top: 100px;
  padding-bottom: 100px;
  position: relative;
}
.headerGrph {
  font-family: "Praxis-Heavy" !important;
  font-size: 26px !important;
  color: #fff;
  text-align: center;
  margin-bottom: 40px !important;
}
.headerGrph span {
  font-size: 26px !important;
  color: #e4026e;
  font-family: "Praxis-Heavy" !important;
}
.graphBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: auto;
  align-items: center;
}
.GrowBox {
  width: 95%;
  height: 200px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.gselBox {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
}
.grLabelBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}
.grLabelBox img {
  width: 20px;
}
.grLabelBox p {
  font-family: "Praxis-Heavy" !important;
  color: #fff;
  font-size: 15px !important;
}
.grSubLabel {
  color: #fff;
  font-size: 13px !important;
}
.leftBorder {
  border-right: #e4026e 3px solid;
  position: relative;
}
.leftBorderGray {
  border-right: #ffffff 3px solid;
  position: relative;
}
.topDot {
  width: 16px;
  height: 16px;
  background: #e4026e;
  border-radius: 50%;
  position: absolute;
  top: -10px;
  right: -10px;
}
.bottomDot {
  width: 16px;
  height: 16px;
  background: #ffffff;
  border-radius: 50%;
  position: absolute;
  bottom: -10px;
  right: -10px;
}

.entryBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-top: 20px;
  padding-bottom: 88px;
}
.entryRowBox {
  width: 95%;
  padding: 14px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-items: center;
  gap: 20px;
}
.entryRowBox img {
  width: 70px;
}
.enBorder {
  border-top: #e0e0e0 1px solid;
  border-bottom: #e0e0e0 1px solid;
}
.entryRowBox p {
  font-size: 12px !important;
  color: #ffff;
}
.entryRowBox span {
  font-size: 16px !important;
  color: #e4026e;
}
.hegpmb {
  margin-bottom: 0px !important;
}
.hero2ndSection .redBlur1 {
  position: absolute;
  top: -170px;
  right: 0px;
  rotate: 180deg;
}
.hero2ndSection .redBlur2 {
  position: absolute;
  top: 220px;
  left: 0px;
}
.hero2ndSection .bagPackBg {
  position: absolute;
  top: 18px;
  left: 0px;
  width: 134px;
}
.hero2ndSection .locationTrackBg {
  position: absolute;
  top: -19px;
  right: 0px;
  width: 185px;
}
.hero2ndSection .locationTrackBg2 {
  position: absolute;
  top: 626px;
  left: 0px;
  width: 192px;
}

.graphSection .mountenBg1 {
  position: absolute;
  top: 27px;
  right: 0px;
  width: 165px;
}
.graphSection .tentBg {
  width: 80px;
  position: absolute;
  top: 622px;
  left: 0px;
}

.graphSection .redBlur {
  position: absolute;
  top: 183px;
  right: 0px;
  rotate: 180deg;
}
.graphSection .locationTraker {
  position: absolute;
  top: 366px;
  right: 0px;
  width: 218px;
}

.entryBox .mounten2bg {
  width: 60%;
  position: absolute;
  top: 0px;
  right: 0px;
}
.entryBox .redBg {
  position: absolute;
  top: -93px;
  left: 0px;
}
.entryBox .mounten3bg {
  width: 204px;
  position: absolute;
  top: 274px;
  left: 0px;
}
.entryBox .redBg2 {
  position: absolute;
  top: 160px;
  right: 0px;
  rotate: 180deg;
}

.appBtn {
  width: 260px;
  height: 50px;
  background: #e4026e;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 26px;
  cursor: pointer;
  transition: all 0.5s;
}
.appBtn:hover {
  background: #ac0254;
}
.appBtn p {
  color: #fff;
  font-weight: 600 !important;
}
.bgImg {
  width: 100%;
  position: absolute;
  top: 0px;
  height: 100%;
  z-index: -2;
}
.HomeBgTabe {
  display: none;
}
.HomeBg {
  display: none;
}
.homeTopBtnBox {
  width: 100%;
  display: none;
  flex-direction: row;
  position: absolute;
  top: 0px;
  justify-content: end;
  gap: 30px;
  padding-right: 20%;
  margin-top: 20px;
}
.homeTopBtnBox .appBtn {
  width: 142px;
  height: 40px;
}
.whiteBtn {
  width: 142px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 26px;
  cursor: pointer;
  transition: all 0.5s;
  border: 2px #fff solid;
  cursor: pointer;
  transition: all 0.5s;
}
.whiteBtn:hover {
  background: rgba(0, 0, 0, 0.226);
  border: 2px rgb(255 255 255 / 68%) solid;
}
.whiteBtn p {
  font-weight: 600 !important;
  color: #fff;
}
.widget-visible iframe {
  right: 5px !important;
  bottom: 54px !important;
}

.tawk-min-container .tawk-button-circle.tawk-button-large {
  width: 35px !important;
  height: 35px !important;
}
.tawk-min-container .tawk-button-circle.tawk-button-large svg {
  width: 16px !important;
}
.widget-visible iframe {
  right: 2px !important;
}

/* Tabe view */
@media only screen and (min-width: 768px) {
  .HomeBgMobile {
    display: none;
  }
  .HomeBgTabe {
    display: block;
  }
  .BookingCardBox {
    padding: 0 20px;
    flex-direction: row;
    gap: 30px;
  }
  .bookingCardI1mg {
    top: -102px;
    width: 121px;
  }
  .bookingCardI2mg {
    top: -102px;
    width: 179px;
  }
  .bookingCardI3mg {
    top: -102px;
    width: 157px;
  }
  .hero2ndSection .locationTrackBg {
    top: -19px;
    width: 185px;
  }
  .hero2ndSection .bagPackBg {
    top: -84px;
    width: 134px;
  }
  .hero2ndSection .locationTrackBg2 {
    top: 626px;
    width: 192px;
  }
  .graphSection .mountenBg1 {
    top: 9px;
    width: 284px;
  }
  .homeHeader {
    font-size: 72px !important;
  }
  .homeSubText {
    font-size: 20px !important;
  }
  .tikTextBox img {
    width: unset;
  }
  .tikTextBox p {
    font-size: 18px !important;
  }
  .homeContactBox .NIconBox {
    width: 40px;
    height: 40px;
  }
  .homeContactBox .NIconBox img {
    width: unset;
  }
  .heroSection {
    gap: 8px;
    justify-content: center;
    padding-top: 0px;
  }
  .homeContactBox {
    gap: 30px;
  }
  .h2Header img {
    width: unset;
  }
  .h2Header p {
    font-size: 43px !important;
  }
  .hero2SubHeader {
    font-size: 18px !important;
  }
  .rightPadding {
    padding-right: 62px;
  }
  .leftPadding {
    padding-left: 62px;
  }
  .headerGrph {
    font-size: 46px !important;
  }
  .headerGrph span {
    font-size: 46px !important;
  }
  .rLine {
    width: unset;
  }
  .wline {
    width: unset;
  }
  .graphImg1 {
    width: 50%;
  }
  .graphImg2 {
    width: 60%;
  }
  .graphImg3 {
    width: 50%;
  }
  .grLabelBox img {
    width: unset;
  }
  .grLabelBox p {
    font-size: 28px !important;
  }
  .grSubLabel {
    font-size: 16px !important;
  }
  .entryRowBox img {
    width: unset;
  }
  .entryRowBox p {
    font-size: 24px !important;
  }
  .entryRowBox span {
    font-size: 24px !important;
  }
  .graphSection .tentBg {
    width: unset;
    top: 390px;
  }
  .entryBox .mounten3bg {
    width: unset;
    width: 280px;
    top: 340px;
  }
  .bookingCard span {
    font-size: 12px !important;
  }
  .bookingCard p {
    font-size: 20px !important;
  }
  .homeTikBox {
    padding: 0px;
    gap: 20px;
  }
  .homeTopBtnBox {
    padding-right: 3%;
    display: flex;
  }
  .entryBox .mounten2bg {
    width: unset;
  }
  .entryRowBox {
    width: 95%;
    padding: 68px 0;
  }
  .entryRowBox img {
    width: 100px;
  }
  .entryBox {
    margin-top: 0px;
    padding-bottom: 0px;
  }
  .GrowBox {
    height: 250px;
  }
}

/* Desktop view */
@media only screen and (min-width: 1000px) {
  .HomeBgTabe {
    display: none;
  }
  .HomeBg {
    display: block;
  }
  .BookingCardBox {
    padding: 0;
  }
  .hero2ndSection .locationTrackBg {
    width: unset;
    top: -69px;
  }
  .hero2ndSection .bagPackBg {
    width: unset;
    top: -134px;
  }
  .hero2ndSection .locationTrackBg2 {
    width: unset;
    top: 540px;
  }
  .graphSection .mountenBg1 {
    width: unset;
    top: -70px;
  }
  .GrowBox {
    width: 60%;
  }
  .entryRowBox {
    width: 50%;
  }
  .tikTextBox p {
    font-size: 24px !important;
  }
  .bookingCard span {
    font-size: 16px !important;
  }
  .bookingCard p {
    font-size: 24px !important;
  }
  .homeTopBtnBox {
    padding-right: 20%;
  }
}
